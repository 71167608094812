.responsive-modal {
    .modal-dialog {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: none;
        margin: 0;

        .modal-content {
            padding: 24px 24px 16px;
            border: none;
            border-radius: 8px 8px 0 0;

            .modal-header {
                padding: 0;
            }

            .modal-body {
                display: flex;
                min-height: 400px;
                max-height: 400px;
                flex-direction: column;
                padding: 0;
                overflow-y: auto;
            }
        }
    }

    &.club-coupon-summary {
        .modal-dialog {
            height: calc(100% - 48px);

            .modal-content {
                .modal-body {
                    max-height: 100%;
                }
            }
        }
    }

    &#out-of-stock-cart-modal {
        .title {
            font-size: 24px;
            line-height: 28px;
        }

        .subtitle {
            margin-top: 16px;
            margin-bottom: 0;
            font-family: $font-base;
            font-size: 14px;
            line-height: 22px;
            text-align: left;
        }

        .items {
            flex: 1;
            margin-top: 8px;
            overflow-y: auto;

            @extend .vertical-scrollbar;

            .item {
                display: flex;
                align-items: center;
                padding: 16px 0;
                border-bottom: 1px solid $gray-f2;
                gap: 8px;

                .content {
                    font-family: $font-base-semi-bold;
                    font-size: 14px;
                    line-height: 22px;

                    .item-info {
                        font-family: $font-base;
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .btn-secondary-old {
            margin-top: 24px;
            color: $white;
        }
    }

    &#club-coupon-modal {
        .club-coupon-row {
            &:hover {
                background-color: $gray-50;
            }

            &:active,
            &:focus {
                background-color: $gray-100;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .modal-dialog {
            position: relative;
            width: 632px;
            margin: 160px auto 0;

            .modal-content {
                padding: 32px 40px 24px;
                border-radius: 8px;

                .modal-body {
                    min-height: auto;
                    max-height: 310px;
                }
            }
        }

        &.club-coupon-summary {
            .modal-dialog {
                height: auto;

                .modal-content {
                    .modal-body {
                        max-height: 100%;
                    }
                }
            }
        }

        &.show {
            display: flex !important;
            align-items: center;
            justify-content: center;

            .modal-dialog {
                margin: 0;
            }
        }

        &#out-of-stock-cart-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        min-height: 324px;
                    }
                }
            }

            .subtitle {
                font-size: 16px;
                line-height: 22px;
            }

            .items {
                margin-top: 24px;
            }

            .btn-secondary-old {
                max-width: 200px;
                margin: 24px auto 0;
            }
        }
    }

    @media (width < 372px) {
        .coupon-expiration-date {
            gap: 0 !important;

            span {
                width: 100%;
            }
        }
    }
}
