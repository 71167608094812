.category-grid-three {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(2, 1fr);

    > a:nth-child(1) {
        grid-column: 1 / span 2;
    }

    @include media-breakpoint-up(lg) {
        grid-gap: 24px;
        grid-template-columns: repeat(3, 1fr);

        > a:nth-child(1) {
            grid-column: 1;
        }
    }
}

.category-grid-four {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(2, 1fr);

    > a:nth-child(1),
    > a:nth-child(2) {
        grid-column: 1 / span 2;
    }

    @include media-breakpoint-up(lg) {
        grid-gap: 24px;
        grid-template-columns: repeat(2, 1fr);

        > a:nth-child(1) {
            grid-column: 1;
        }

        > a:nth-child(2) {
            grid-column: 2;
        }
    }
}

.category-grid-five {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(2, 1fr);

    > a:nth-child(1),
    > a:nth-child(2) {
        grid-column: 1 / span 2;
    }

    @include media-breakpoint-up(lg) {
        grid-gap: 24px;
        grid-template-columns: repeat(6, 1fr);

        > a:nth-child(1) {
            grid-column: 1 / span 3;
        }

        > a:nth-child(2) {
            grid-column: 4 / span 3;
        }

        > a:nth-child(3) {
            grid-column: 1 / span 2;
        }

        > a:nth-child(4) {
            grid-column: 3 / span 2;
        }

        > a:nth-child(5) {
            grid-column: 5 / span 2;
        }
    }
}

.category-grid-six {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(2, 1fr);

    > a:nth-child(1),
    > a:nth-child(2) {
        grid-column: 1 / span 2;
    }

    @include media-breakpoint-up(lg) {
        grid-gap: 24px;
        grid-template-columns: repeat(36, 1fr);

        > a:nth-child(1) {
            grid-column: 1 / span 12;
        }

        > a:nth-child(2) {
            grid-column: 13 / span 12;
        }

        > a:nth-child(3) {
            grid-column: 25 / span 12;
        }

        > a:nth-child(4) {
            grid-column: 7 / span 8;
        }

        > a:nth-child(5) {
            grid-column: 15 / span 8;
        }

        > a:nth-child(6) {
            grid-column: 23 / span 8;
        }
    }
}

.category-grid-card {
    &:hover::after {
        position: absolute;
        background: linear-gradient(0deg, rgb(255 255 255 / 10%), rgb(255 255 255 / 10%));
        content: '';
        inset: 0;
    }

    &:active::after,
    &:focus::after {
        position: absolute;
        background: linear-gradient(0deg, rgb(255 255 255 / 20%), rgb(255 255 255 / 20%));
        content: '';
        inset: 0;
    }

    .mobile-primary {
        @include media-breakpoint-down(lg) {
            min-width: 200px;
        }
    }

    .desktop-primary {
        @include media-breakpoint-up(lg) {
            min-width: 200px;
        }
    }
}
