//-----------------------------------------------------
// Fade In
//-----------------------------------------------------

$fade-in-animation-duration: 0.5s;

@keyframes fade-in-up {
    from {
        transform: translate(0, 40px);
    }

    to {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.fade-in {
    animation-duration: $fade-in-animation-duration;
    animation-fill-mode: both;
    opacity: 0;
}

.fade-in-up {
    animation-name: fade-in-up;
}

//-----------------------------------------------------
// Marquee
//-----------------------------------------------------

@keyframes marquee {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(calc(-100% - 1rem));
    }
}

.marquee {
    overflow: hidden;

    .marquee-inner {
        display: flex;
        width: fit-content;
        align-items: center;
        animation: marquee 20s linear infinite;
        gap: 48px;
    }

    @include media-breakpoint-up(lg) {
        .marquee-inner {
            flex-wrap: wrap;
            justify-content: center;
            animation: none;
            gap: 88px;
        }
    }
}
