// Fonts

@font-face {
    font-display: swap;
    font-family: Figtree-Regular;
    src: url('../../fonts/figtree/Figtree-Regular.ttf');
}

@font-face {
    font-display: swap;
    font-family: Figtree-Medium;
    src: url('../../fonts/figtree/Figtree-Medium.ttf');
}

@font-face {
    font-display: swap;
    font-family: Figtree-SemiBold;
    src: url('../../fonts/figtree/Figtree-SemiBold.ttf');
}

@font-face {
    font-display: swap;
    font-family: Figtree-Bold;
    src: url('../../fonts/figtree/Figtree-Bold.ttf');
}

@font-face {
    font-display: swap;
    font-family: Figtree-ExtraBold;
    src: url('../../fonts/figtree/Figtree-ExtraBold.ttf');
}
