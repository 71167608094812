.editorial-link {
    &:hover {
        border-color: $green-600 !important;
        background-color: $green-50 !important;
    }

    &:active,
    &:focus {
        border-color: $green-800 !important;
        background-color: $green-100 !important;
    }

    @include media-breakpoint-up(lg) {
        img {
            width: 128px;
            height: 160px;
        }
    }
}
