.mobile-accordion {
    @include media-breakpoint-up(xl) {
        .accordion-item {
            border-bottom: none !important;
        }

        .accordion-button {
            &::after {
                content: none;
            }
        }
    }
}
