.categories-list {
    img {
        width: 32px;
        height: 32px;
    }

    @extend .scrollbar;

    &::-webkit-scrollbar {
        display: none;
        height: 0;
        background: transparent;
    }

    @include media-breakpoint-up(sm) {
        img {
            width: 40px;
            height: 40px;
        }
    }
}

.right-gradient {
    &::after {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        width: 96px;
        height: 100%;
        background: linear-gradient(to left, rgb(255 255 255 / 100%), rgb(255 255 255 / 0%));
        content: '';

        @include media-breakpoint-only(xs) {
            display: none;
        }
    }
}

.left-gradient {
    &::before {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 96px;
        height: 100%;
        background: linear-gradient(to right, rgb(255 255 255 / 100%), rgb(255 255 255 / 0%));
        content: '';

        @include media-breakpoint-only(xs) {
            display: none;
        }
    }
}

.product-index {
    .dropdown-toggle.no-caret {
        &::after {
            content: none;
        }
    }

    .product-list {
        display: grid;
        padding: 0;
        margin-top: 20px;
        margin-bottom: 16px;
        gap: 40px 8px;
        grid-template-columns: repeat(2, 50fr);
    }
}

@include media-breakpoint-up(lg) {
    .product-index {
        .product-list {
            margin-bottom: 32px;
            gap: 48px 32px;
            grid-template-columns: repeat(4, 25fr);

            .product__item {
                margin-bottom: 0;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .product-index {
        .product-list {
            gap: 48px;
        }
    }
}
