.customer-account {
    h1 {
        color: $primary;
        text-align: center;
    }

    h3 {
        margin-top: 16px;
        margin-bottom: 16px;
        color: $primary;
    }

    .title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .account-menu {
        margin-top: 16px;

        &.active {
            ul {
                li.current {
                    a {
                        .icon {
                            transform: rotate(270deg);
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            border-radius: 8px;
            box-shadow: 0 0 10px rgb(0 0 0 / 15%);
            list-style: none;

            li {
                display: none;
                flex-basis: 100%;
                border-right: 1px solid $gray-bf;
                border-bottom: 1px solid $gray-bf;
                border-left: 1px solid $gray-bf;
                text-align: center;

                &.current {
                    display: block;
                    border: 1px solid $primary;
                    border-radius: 8px;
                    background-color: $primary;

                    a {
                        color: $white;

                        .icon {
                            display: block;
                            font-size: 1em;
                            transform: rotate(450deg);
                            transition: all 0.5s;
                        }
                    }
                }

                &:last-child {
                    border-radius: 0 0 8px 8px;

                    &.current {
                        border-radius: 8px;
                    }
                }

                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 19px 16px;
                    font-family: $font-base-bold;

                    .icon {
                        display: none;
                    }
                }
            }
        }
    }

    .customer-section {
        padding: 30px 24px 16px;
        border: 1px solid $gray-bf;
        border-radius: 8px;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 14px;
            border-bottom: 1px solid $gray-bf;
            margin-bottom: 16px;

            .subtitle {
                margin-bottom: 0;
            }

            .icon {
                font-size: 1.5em;
            }
        }

        .content {
            a,
            button {
                margin: 0 auto;
            }

            p {
                margin-bottom: 8px;

                &.label {
                    font-family: $font-base-bold;
                }

                &.caption {
                    margin-top: 16px;
                }
            }

            .form-input {
                margin-bottom: 16px;

                .input {
                    width: 100%;

                    input {
                        width: 100%;
                    }
                }

                .form-label {
                    margin-bottom: 4px;
                }
            }
        }
    }

    .customer-info {
        .content {
            p {
                margin-bottom: 16px;

                &.label {
                    margin-bottom: 8px;
                }
            }
        }
    }

    .last-orders {
        &.full-table {
            margin-bottom: 40px;
        }

        .order-headers {
            display: none;
        }

        .order-item {
            padding: 16px 24px;

            &:nth-child(even) {
                background-color: $gray-f2;
            }

            .order-row {
                display: flex;
                min-height: 56px;
                align-items: center;
                justify-content: space-between;

                .action {
                    min-width: 120px;
                }
            }
        }
    }

    .address-book {
        margin-bottom: 40px;

        .address-list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 16px;
            gap: 16px;

            .address {
                position: relative;
                flex-basis: 100%;
                border-radius: 8px;
                background-color: $gray-f2;

                &.default {
                    background-color: $extra-light-primary;

                    .actions {
                        .set-default {
                            pointer-events: none;

                            .switch {
                                background-color: $primary;

                                .slider {
                                    right: 4px;
                                    left: auto;
                                }
                            }
                        }
                    }
                }

                &.add {
                    padding: 0;

                    a {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        flex-wrap: wrap;
                        align-content: center;
                        justify-content: center;
                        padding: 16px 32px;
                        color: $gray-99;
                        gap: 16px;
                        text-align: center;

                        .icon,
                        p {
                            flex-basis: 100%;
                        }
                    }
                }

                .data {
                    padding: 16px 32px;
                }

                .actions {
                    a {
                        font-family: $font-base-semi-bold;
                    }

                    .delete {
                        position: absolute;
                        top: 16px;
                        right: 0;
                        padding: 0;
                        border: none;
                        background-color: inherit;
                    }

                    .edit,
                    .delete {
                        margin: 0 16px;
                    }

                    .set-default {
                        display: flex;
                        align-items: center;
                        padding: 0;
                        border: none;
                        margin: 16px;
                        background-color: inherit;
                        font-family: $font-base-semi-bold;
                        gap: 8px;

                        .switch {
                            position: relative;
                            width: 68px;
                            height: 38px;
                            flex: 0 0 auto;
                            border-radius: 30px;
                            background-color: $gray-bf;

                            .slider {
                                position: absolute;
                                top: 4px;
                                left: 4px;
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                background-color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    .change-password {
        max-width: 650px;

        .input {
            .icon {
                right: 0;
            }
        }
    }

    .create-address,
    .update-address {
        margin-bottom: 40px;

        .actions {
            text-align: center;

            .return {
                margin-bottom: 16px;
            }
        }
    }

    .order-show {
        .customer-section {
            margin-bottom: 20px;
        }

        .order-summary {
            p {
                margin-bottom: 0;
            }

            .order-number {
                @extend .default-label;

                font-family: $font-base;
                font-size: $font-normal;
            }
        }

        .order-address {
            .data {
                padding: 16px 32px;
                background-color: $gray-f2;
            }
        }

        .shipment,
        .payment {
            margin-bottom: 16px;
        }

        .products {
            .products-headers {
                padding: 8px;
                background-color: $gray-f2;

                .subtitle {
                    display: none;

                    &.main {
                        display: block;
                    }
                }
            }

            .item {
                padding: 8px 0;
                border-bottom: 1px solid $gray-bf;

                .item-row:not(.main) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 8px;
                }

                .item-row.subtotal {
                    align-items: flex-start;

                    .row-content {
                        font-family: $font-base-semi-bold;
                        font-size: $font-medium;

                        .subtotal-prices {
                            display: flex;
                            justify-content: flex-end;
                            gap: 8px;

                            .old-price {
                                color: $gray-99;
                                font-size: $font-normal;
                            }
                        }

                        .subtotal-discount {
                            color: $error;
                            font-size: 12px;
                        }
                    }
                }

                .image {
                    text-align: center;

                    img {
                        margin-bottom: 8px;
                    }
                }

                .product-name {
                    color: $primary;
                    font-family: $font-base-semi-bold;
                }

                .old-price {
                    text-decoration: line-through;
                }

                .unit-price {
                    font-family: $font-base-semi-bold;
                }
            }
        }

        .totals {
            .totals-row {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                padding: 16px 0;

                &:not(.order-total) {
                    border-bottom: 2px solid $gray-99;
                }

                &.shipping {
                    gap: 12px;

                    .title {
                        display: block;
                    }
                }

                .title {
                    font-family: $font-base-semi-bold;
                }

                .bulk-product-warning {
                    display: flex;
                    align-items: center;
                    margin-top: 8px;
                    font-family: $font-base;
                    gap: 8px;

                    .icon {
                        color: $warning;
                    }
                }
            }

            .products-row {
                border-bottom: 1px solid $gray-99;

                .totals-row {
                    border-bottom: none;
                    gap: 12px;

                    .title {
                        display: block;
                        flex-basis: 60%;
                    }

                    .content {
                        display: flex;
                        flex-basis: 40%;
                        justify-content: space-between;
                    }
                }
            }
        }
    }

    @media #{$responsive-query} {
        .account-menu {
            &.active {
                ul {
                    li {
                        display: block;

                        &.current {
                            border-radius: 8px 8px 0 0;
                        }
                    }
                }
            }

            ul {
                li {
                    order: 1;

                    &.current {
                        order: 0;
                    }
                }
            }
        }
    }

    @media #{$big-query} {
        .title {
            &::before {
                flex: 1;
                content: '';
            }

            .logout {
                flex: 1;
                text-align: center;
            }
        }

        .account-menu {
            margin-bottom: 32px;

            ul {
                width: fit-content;
                flex-wrap: nowrap;
                justify-content: center;
                margin: 0 auto;

                li {
                    display: block;
                    min-width: 160px;
                    flex-basis: auto;
                    border-right: none;
                    border-bottom: none;

                    &.current {
                        border-radius: 0;

                        a .icon {
                            display: none;
                        }
                    }

                    &:first-child {
                        border-radius: 8px 0 0 8px;
                        border-left: none;
                    }

                    &:last-child,
                    &:last-child.current {
                        border-radius: 0 8px 8px 0;
                    }

                    a {
                        display: block;
                    }
                }
            }
        }

        .customer-section {
            margin-bottom: 40px;
        }

        .last-orders {
            border: 1px solid $gray-bf;
            border-radius: 8px;

            &.full-table {
                .order-headers {
                    .subtitle {
                        flex-basis: 20%;
                    }
                }

                .order-item {
                    .order-row {
                        flex: 0 0 20%;
                    }
                }
            }

            .order-headers,
            .order-item {
                padding: 8px 24px;
            }

            .order-headers {
                display: flex;

                .subtitle {
                    flex-basis: 25%;
                }
            }

            .order-item {
                display: flex;

                .order-row {
                    flex-basis: 25%;

                    .subtitle {
                        display: none;
                    }
                }
            }
        }

        .address-book {
            .address-list {
                .address {
                    flex-basis: 32%;

                    .actions {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: space-between;

                        .delete {
                            position: static;
                        }
                    }
                }
            }
        }

        .fronda-club {
            margin-bottom: 0;
        }

        .create-address,
        .update-address {
            .actions {
                display: flex;
                justify-content: center;
                gap: 32px;

                .return {
                    display: flex;
                    align-items: center;
                }

                button,
                .return {
                    margin: 0;
                }
            }
        }

        .order-show {
            .order-summary {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
                gap: 5px;

                .subtitle {
                    flex: 1;
                }
            }

            .shipment,
            .payment {
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    margin-bottom: 0;
                }
            }

            .products {
                .products-headers {
                    display: flex;
                    justify-content: space-between;

                    .subtitle {
                        display: block;
                        flex-basis: 16%;
                        text-align: center;

                        &.main {
                            flex-basis: 50%;
                            text-align: left;
                        }

                        &.subtotal {
                            flex-basis: 30%;
                        }
                    }
                }

                .item {
                    display: flex;
                    justify-content: space-between;

                    .item-row {
                        flex-basis: 16%;

                        &.main {
                            display: flex;
                            flex-basis: 50%;
                            align-items: center;
                            gap: 8px;
                            text-align: left;
                        }

                        &:not(.main) {
                            justify-content: center;
                            text-align: center;
                        }

                        &.subtotal {
                            flex-basis: 30%;
                            align-items: center;
                        }

                        .subtitle {
                            display: none;
                        }
                    }

                    .image {
                        text-align: left;
                    }
                }
            }
        }
    }
}
