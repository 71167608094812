.product-list {
    position: relative;

    &.exclusive-variants {
        .product__item {
            min-width: 300px;
            max-width: 300px;
        }
    }

    .product__item {
        transition: all 0.08s ease-in-out;

        .hover-image {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: opacity 0.2s;
        }

        .item-option {
            &:hover {
                background-color: $green-700 !important;
                color: $white !important;
            }

            &:active,
            &:focus {
                background-color: $green-950 !important;
                color: $white !important;
            }
        }

        .item-name {
            font-size: 15px;
            line-height: 20px;
        }
    }

    @include media-breakpoint-up(lg) {
        .product-list-subtitle {
            max-width: 70%;
        }

        .product__item {
            &:hover {
                .hover-image {
                    opacity: 1;
                }
            }

            .item-name {
                font-size: 16px;
            }
        }
    }
}
