.navigation-wrapper {
    position: relative;

    .arrow-left {
        left: -24px;

        &::after {
            content: $icon-chevron-left;
        }
    }

    .arrow-right {
        right: -24px;

        &::after {
            content: $icon-chevron-right;
        }
    }

    .arrow {
        position: absolute;
        top: 50%;
        display: none;
        width: 48px;
        height: 48px;
        border: 1px solid $gray-300;
        border-radius: 50%;
        background-color: $white;
        box-shadow:
            0 4px 12px 0 rgb(0 0 0 / 10%),
            0 2px 2px 0 rgb(0 0 0 / 8%);
        cursor: pointer;
        transform: translateY(-50%);

        &::after {
            color: $gray-950;
            font-family: $icomoon-font-family;
            font-size: 15px;
            font-weight: 900;
        }

        &:hover {
            border-color: $green-700;
            background-color: $green-700;

            &::after {
                color: $white;
            }
        }

        &:active,
        &:focus {
            border-color: $green-900;
            background-color: $green-900;

            &::after {
                color: $white;
            }
        }
    }

    .arrow-disabled {
        border-color: transparent;
        background-color: transparent;
        box-shadow: none;

        &:hover {
            border: none;
            background-color: transparent;

            &::after {
                color: transparent;
            }
        }

        &::after {
            color: transparent;
        }
    }

    @include media-breakpoint-up(md) {
        .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.slider-placeholder {
    .placeholder {
        min-height: 270px;

        @include media-breakpoint-up(md) {
            min-height: 290px;
        }

        @include media-breakpoint-up(xl) {
            min-height: 310px;
        }

        @include media-breakpoint-up(xxl) {
            min-height: 380px;
        }
    }
}
