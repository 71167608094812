.featured-post-card {
    position: relative;
    min-height: 500px;
    max-height: 750px;
    margin-bottom: 40px;

    &.dark {
        .content-wrapper {
            background: linear-gradient(0deg, rgb(0 0 0 / 40%), rgb(0 0 0 / 40%));

            .content {
                h2,
                p,
                a {
                    color: $white;
                }
            }
        }

        img {
            min-height: 500px;
            max-height: 750px;
        }
    }

    &.light {
        .content-wrapper {
            width: auto;
            background-color: $white;
        }
    }

    img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .content-wrapper {
        position: absolute;
        top: 0;
        display: flex;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
        align-content: center;
        padding: 48px;

        .content {
            max-width: 400px;

            > * {
                margin-bottom: 8px;
            }

            .link {
                a {
                    display: inline-flex;
                    align-items: center;
                    gap: 8px;

                    .icon {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    @media #{$big-query} {
        &.right {
            .content-wrapper {
                right: 0;
                justify-content: flex-end;
            }
        }
    }

    @media #{$responsive-query} {
        &.light {
            .content-wrapper {
                position: relative;
                height: auto;
                padding: 24px;
            }
        }
    }
}

.tag {
    width: fit-content;
    padding: 8px 16px;
    border: 2px solid $success;
    border-radius: 4px;
    background-color: $light-success;
    color: $success;
}
