.pets-promo {
    margin-top: 24px;

    .image {
        img {
            width: 100%;
            max-height: 332px;
            border-radius: 4px 4px 0 0;
            object-fit: cover;
            object-position: top;
        }
    }

    .content {
        padding: 32px 24px;
        border-radius: 0 0 4px 4px;
        background: $gray-f2;

        .title {
            font-family: $font-base-semi-bold;
            font-size: 28px;
            letter-spacing: -0.01em;
            line-height: 28px;
        }

        .subtitle {
            padding: 8px;
            margin-top: 16px;
            background-color: $dark-primary;
            color: $white;
            font-family: $font-base-bold;
            font-size: 12px;
            letter-spacing: 0.03em;
            line-height: 16px;
            text-transform: uppercase;
        }

        ul {
            padding: 0;
            margin-top: 32px;
            list-style-type: none;

            li {
                position: relative;
                padding-left: 48px;
                margin-bottom: 16px;
                counter-increment: li-counter;

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    width: 40px;
                    height: 40px;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background-color: $dark-primary;
                    color: $white;
                    content: counter(li-counter);
                    font-family: $font-base-semi-bold;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        .logos {
            margin-top: 32px;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .caption {
            margin-top: 32px;
            color: $gray-99;
            font-family: $font-base-semi-bold;
            font-size: 10px;
            letter-spacing: 0.01em;
            line-height: 12px;
        }
    }

    @media #{$big-query} {
        display: flex;
        margin-top: 65px;

        .image {
            flex: 1 0 360px;

            img {
                min-height: 100%;
                border-radius: 4px 0 0 4px;
            }
        }

        .content {
            padding: 40px 80px;
            border-radius: 0 4px 4px 0;

            .title {
                font-size: 32px;
                line-height: 32px;
            }

            .subtitle {
                margin-top: 24px;
            }
        }
    }
}

.large-icon {
    font-size: 50px;
}
