.post-banner-fullwidth {
    position: relative;
    margin-bottom: 24px;

    img {
        max-width: 100%;
        height: 738px;
        object-fit: cover;
    }

    .content-wrapper {
        position: absolute;
        bottom: 0;
        display: flex;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        background: linear-gradient(0deg, rgb(0 0 0 / 40%), rgb(0 0 0 / 40%));

        .content {
            max-width: 870px;
            text-align: center;

            > * {
                margin-bottom: 16px;
                color: $white;
            }

            .icon {
                display: block;
                margin: 32px auto;
            }
        }
    }

    @include media-breakpoint-up(md) {
        img {
            height: 400px;
        }
    }

    @media #{$big-query} {
        .content-wrapper {
            .content {
                .banner-subtitle {
                    font-family: $font-base-semi-bold;
                    font-size: $font-heading-4;
                    line-height: 27px;
                }
            }
        }
    }
}

.post-banner-split {
    .image {
        width: 100%;
    }

    a:hover {
        color: $green-600 !important;
    }

    a:active,
    a:focus {
        color: $green-900 !important;
    }

    @include media-breakpoint-up(lg) {
        .image {
            width: 55%;
        }
    }
}
