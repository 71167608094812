.thank-you-page {
    h3 {
        color: $primary;
    }

    .thank-you-banner {
        margin-top: 20px;
        margin-bottom: 50px;

        .content-wrapper {
            background: linear-gradient(0deg, #527561, #527561);
            color: $white;
            text-align: center;

            &.two-cols {
                text-align: left;
            }

            .content {
                max-width: 550px;
                padding: 40px 0;
                margin: 0 auto;

                h2 {
                    margin-bottom: 24px;
                    color: $white;
                    font-size: 44px;
                    line-height: 56px;
                }

                .subtitle {
                    font-family: $font-base;
                    font-size: 15px;
                    line-height: 28px;
                }

                p {
                    margin-bottom: 8px;
                }

                .payment-method-instructions {
                    padding: 24px;
                    border-radius: 8px;
                    margin: 24px auto;
                    background: $dark-primary;
                    font-family: $font-base-bold;
                    text-align: left;

                    .payment-info-data {
                        font-size: 11px;
                        line-height: 13px;
                    }

                    .order-number-indication {
                        font-family: $font-base;
                        font-size: 12px;
                        line-height: 18px;
                    }

                    p {
                        margin-bottom: 16px;
                    }

                    p:last-child {
                        margin-bottom: 0;
                    }
                }

                .inline-icon {
                    padding-top: 24px;
                    border-top: 1px solid $white;
                    gap: 16px;

                    .inline-icon-content {
                        font-size: 13px;
                        line-height: 20px;

                        p {
                            margin-bottom: 0;
                        }
                    }
                }

                .order-total {
                    font-family: $font-base-bold;
                    font-size: 14px;
                    line-height: 21px;
                }

                .order-address {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        .register {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            padding: 32px 24px;
            background-color: $sand;
            text-align: center;

            .subtitle {
                color: $primary;
            }

            p {
                font-family: $font-base-bold;
            }

            .btn-secondary-old {
                min-width: 0;
                padding: 14px 32px;
            }
        }
    }

    @media #{$big-query} {
        .thank-you-banner {
            margin-top: 32px;

            .content-wrapper {
                display: flex;
                min-height: 470px;
                align-items: center;
            }

            .register {
                justify-content: flex-start;
                text-align: left;
            }
        }
    }
}
