//======================================================================
// accordion
//======================================================================

$accordion-icon-width: 24px;
$accordion-button-icon: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.71967 9.71967C8.01256 9.42678 8.48744 9.42678 8.78033 9.71967L12 12.9393L15.2197 9.71967C15.5126 9.42678 15.9874 9.42678 16.2803 9.71967C16.5732 10.0126 16.5732 10.4874 16.2803 10.7803L12.5303 14.5303C12.3897 14.671 12.1989 14.75 12 14.75C11.8011 14.75 11.6103 14.671 11.4697 14.5303L7.71967 10.7803C7.42678 10.4874 7.42678 10.0126 7.71967 9.71967Z" fill="#171717"/></svg>');
$accordion-button-active-icon: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.71967 9.71967C8.01256 9.42678 8.48744 9.42678 8.78033 9.71967L12 12.9393L15.2197 9.71967C15.5126 9.42678 15.9874 9.42678 16.2803 9.71967C16.5732 10.0126 16.5732 10.4874 16.2803 10.7803L12.5303 14.5303C12.3897 14.671 12.1989 14.75 12 14.75C11.8011 14.75 11.6103 14.671 11.4697 14.5303L7.71967 10.7803C7.42678 10.4874 7.42678 10.0126 7.71967 9.71967Z" fill="#171717"/></svg>');

//======================================================================
// carousel
//======================================================================

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M5.40533 9.15533C5.69822 8.86244 5.69822 8.38756 5.40533 8.09467L2.18566 4.875L5.40533 1.65533C5.69822 1.36244 5.69822 0.887564 5.40533 0.59467C5.11244 0.301777 4.63756 0.301777 4.34467 0.59467L0.59467 4.34467C0.454018 4.48532 0.375 4.67609 0.375 4.875C0.375 5.07391 0.454018 5.26468 0.59467 5.40533L4.34467 9.15533C4.63756 9.44822 5.11244 9.44822 5.40533 9.15533Z' fill='#262626'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M0.71967 9.28033C0.426777 8.98744 0.426777 8.51256 0.71967 8.21967L3.93934 5L0.71967 1.78033C0.426776 1.48744 0.426776 1.01256 0.71967 0.71967C1.01256 0.426777 1.48744 0.426777 1.78033 0.71967L5.53033 4.46967C5.67098 4.61032 5.75 4.80109 5.75 5C5.75 5.19891 5.67098 5.38968 5.53033 5.53033L1.78033 9.28033C1.48744 9.57322 1.01256 9.57322 0.71967 9.28033Z' fill='#262626'/></svg>");
$carousel-control-opacity: 1;
$carousel-control-hover-opacity: 1;
$carousel-transition-duration: 0.3s;

//======================================================================
// form
//======================================================================

$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M2.40002 6.75L5.40002 9.75L9.90002 3' stroke='#ffffff' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/></svg>");

//======================================================================
// navbar
//======================================================================

$navbar-light-toggler-border-color: transparent;

//======================================================================
// spinner
//======================================================================

$spinner-width: 1rem;
$spinner-height: $spinner-width;
$spinner-border-width: 0.15em;

//======================================================================
// tooltip
//======================================================================

$tooltip-font-size: 12px;
$tooltip-color: #fff;
$tooltip-bg: $gray-950;
$tooltip-max-width: 200px;
$tooltip-border-radius: 2px;
$tooltip-opacity: 1;
$tooltip-padding-y: 4px;
$tooltip-padding-x: 8px;

//======================================================================
// typography
//======================================================================

$display-font-sizes: (
    1: 80px,
    2: 72px,
    3: 64px,
    4: 56px,
    5: 56px,
    6: 48px,
);
$display-line-height: 1;
