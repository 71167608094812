.image-block {
    margin: 16px auto;

    &.inline {
        max-width: 648px;
    }

    &.overflow {
        max-width: 870px;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.image-row-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 16px auto;
    gap: 16px;

    &.inline {
        max-width: 648px;
    }

    &.overflow {
        max-width: 870px;
    }

    img {
        max-width: calc(50% - 8px);
        height: auto;
        flex-basis: calc(50% - 8px);
    }

    .caption {
        color: $gray-99;
    }
}

.image-text-block {
    margin: 16px auto;

    .image {
        img {
            max-width: 100%;
            height: auto;
            margin-bottom: 16px;
        }
    }

    h4 {
        margin-bottom: 16px;
    }

    @media #{$big-query} {
        display: flex;
        align-items: center;
        gap: 32px;

        &.left {
            .image {
                order: 2;
            }
        }

        &.inline {
            max-width: 648px;

            .image,
            .content {
                flex-basis: 50%;
            }
        }

        &.overflow {
            max-width: 870px;

            .image {
                flex-basis: 33%;
            }

            .content {
                flex-basis: 66%;
            }
        }

        .image {
            .caption {
                text-align: center;
            }
        }
    }
}

.image-text-row-block {
    margin-bottom: 40px;

    .item {
        img {
            max-width: 100%;
        }

        h4 {
            margin: 24px 0 16px;
        }
    }

    @media #{$big-query} {
        display: flex;
        max-width: 870px;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;

        .item {
            flex-basis: 32%;
        }
    }
}
