.holiday-delivery-suggestions {
    display: flex;
    max-width: 400px;
    align-items: center;
    color: $success;
    cursor: pointer;
    gap: 8px;

    .content {
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
    }
}

#holiday-delivery-suggestions-modal {
    color: $gray-33;

    p {
        margin-bottom: 0;
    }

    .modal-dialog {
        max-width: 632px;
    }

    .modal-header {
        align-items: flex-start;
        padding: 32px 40px 0;
        gap: 6px;

        .modal-title {
            font-family: $font-base-semi-bold;
            font-size: 24px;
            line-height: 28px;
        }

        .icon {
            font-size: 18px;
            line-height: 28px;
        }
    }

    .modal-body {
        padding: 0 40px;

        .modal-col {
            margin-top: 32px;

            .col-title {
                margin-bottom: 20px;
                font-family: $font-base-semi-bold;
                font-size: 20px;
                line-height: 24px;
            }

            .shipping-info {
                padding-bottom: 12px;
                border-bottom: 1px solid $gray-f2;
                margin-bottom: 12px;

                &:last-child {
                    padding-bottom: 0;
                    border-bottom: none;
                    margin-bottom: 0;
                }

                .info-title {
                    font-family: $font-base-bold;
                    font-size: 12px;
                    line-height: 16px;
                    text-transform: uppercase;
                }

                .info-content {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    .modal-footer {
        padding: 0;
        border-top: none;
        margin: 24px auto;

        .btn-secondary-old {
            min-width: 0;
            padding: 14px 32px;
        }
    }
}
