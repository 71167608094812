.post-header {
    .icon {
        font-size: 24px;
    }

    .title {
        background-color: #303e3b;
    }

    .content {
        color: #303e3b;
    }

    @include media-breakpoint-up(sm) {
        .content {
            width: auto !important;
        }
    }
}
