.fronda-club-register {
    margin-top: 40px;

    .form {
        padding: 24px;
        border: 1px solid $gray-f2;
        border-radius: 8px;
        box-shadow: 0 0 10px rgb(0 0 0 / 15%);

        .form-input {
            .input {
                width: auto;

                input {
                    width: 100%;
                }
            }
        }

        .cta {
            margin-top: 24px;
        }
    }

    @media #{$big-query} {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 80px;
        gap: 64px;

        .form {
            flex: 0 0 60%;
        }
    }
}

.benefits-title {
    @extend .h2-alter;

    max-width: 520px;
    margin: 40px auto 0;
    color: $club-secondary;
    text-align: center;

    @media #{$big-query} {
        margin-top: 80px;
    }
}

.benefits-icon {
    margin: 24px auto 40px;
    text-align: center;

    .icon {
        color: $light-club;
        font-size: 54px;
    }

    @media #{$big-query} {
        margin-bottom: 80px;
    }
}

.club-benefits {
    .benefit {
        max-width: 316px;
        margin: 40px auto 0;
        text-align: center;
    }

    @media #{$big-query} {
        display: flex;
        justify-content: space-evenly;

        .benefit {
            margin: 0;
        }
    }
}

.earn-rewards {
    .content {
        .reward-list {
            .item {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                margin-top: 24px;
                gap: 8px;

                .icon {
                    color: $club-secondary;
                    font-size: 18px;
                    line-height: 27px;
                }

                .text {
                    flex: 1;
                    color: $club-secondary;

                    p {
                        display: flex;
                        flex-wrap: wrap;

                        .title {
                            @extend h4;

                            flex-basis: 100%;
                            color: $club-secondary;
                        }
                    }
                }
            }
        }

        .cta {
            text-align: center;
        }
    }

    .image {
        margin-top: 30px;

        img {
            max-width: 100%;
        }
    }

    @media #{$big-query} {
        display: flex;
        align-items: center;

        .content {
            flex-basis: 60%;

            .reward-list {
                .item {
                    .text {
                        p {
                            display: block;
                        }
                    }
                }
            }

            .cta {
                text-align: left;
            }
        }

        .image {
            flex-basis: 40%;
        }
    }
}

.club-terms {
    li {
        margin-top: 24px;
    }
}
