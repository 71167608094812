.newsletter-subscribe {
    margin-top: 8px;
    margin-bottom: 40px;

    h2 {
        max-width: 600px;
        margin: 0 auto;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
    }

    .newsletter-form {
        max-width: 525px;
        margin: 0 auto;

        .form-email {
            margin-top: 16px;

            input {
                height: 56px;
                border: 1px solid $gray-bf;
                box-shadow: none;

                &:active,
                &:focus {
                    border-color: $primary;
                }
            }

            button {
                width: 100%;
                margin-top: 16px;
            }
        }

        .form-check {
            font-size: $font-small;
        }

        .legal-warning {
            margin-top: 16px;
            text-align: center;

            a {
                text-decoration: underline;
            }

            .form-check-input {
                float: none;
            }
        }

        .msn-error {
            border: none;
            margin-top: 16px;
            background-color: #ffebeb;
            color: $error;
        }

        #success-message {
            padding: 16px 0 0;
            border: none;
            border-radius: 0;
            border-top: 1px solid $gray-bf;
            margin-top: 24px;
            gap: 16px;

            .icon {
                color: $gray-800;
                font-size: 32px;
            }
        }
    }

    @media #{$big-query} {
        margin-top: 40px;
        margin-bottom: 80px;

        h2 {
            font-size: 24px;
            line-height: 28px;
        }

        .newsletter-form {
            .form-email {
                max-width: none;
                margin: 32px 0 0;

                button {
                    position: absolute;
                    top: 4px;
                    right: 4px;
                    width: auto;
                    min-width: 180px;
                    margin-top: 0;
                }
            }

            .legal-warning {
                margin-top: 20px;
                text-align: left;
            }
        }
    }
}
