@mixin label($color, $background-color) {
    display: inline-block;
    padding: 8px;
    border-radius: 8px;
    background-color: $background-color;
    color: $color;
}

.default-label {
    @include label($gray-33, $gray-bf);
}

.info-label {
    @include label($gray-4f, $yellow-stars);
}

.warning-label {
    @include label($white, $warning);
}

.error-label {
    @include label($white, $error);
}

.success-label {
    @include label($white, $green-apple);
}
