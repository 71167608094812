$pink-50: #fef0f1;
$pink-100: #fde7ea;
$pink-200: #fbd0d7;
$pink-300: #f7abb6;
$pink-400: #ed8294;
$pink-500: #e34f6c;
$pink-600: #d12e57;
$pink-700: #b02147;
$pink-800: #951d41;
$pink-900: #7d1c3e;
$pink-950: #470b1e;
$blue-50: #f1f7fd;
$blue-100: #dfecfa;
$blue-200: #c6def7;
$blue-300: #9fcaf1;
$blue-400: #72ade8;
$blue-500: #518ee0;
$blue-600: #3c74d4;
$blue-700: #325ebf;
$blue-800: #2f4f9e;
$blue-900: #2b447d;
$blue-950: #1e2b4d;
$rose-50: #fef2f2;
$rose-100: #fee2e2;
$rose-200: #fdcbcb;
$rose-300: #fba6a6;
$rose-400: #f67373;
$rose-500: #ed4646;
$rose-600: #da2828;
$rose-700: #b71e1e;
$rose-800: #971d1d;
$rose-900: #681919;
$rose-950: #440b0b;
$brown-50: #fbf8f4;
$brown-100: #f4ebe0;
$brown-200: #e8d6c0;
$brown-300: #d8ba99;
$brown-400: #c8986f;
$brown-500: #bc8053;
$brown-600: #af6d47;
$brown-700: #91563d;
$brown-800: #764736;
$brown-900: #603c2e;
$brown-950: #331d17;
$amber-50: #fffbeb;
$amber-100: #fff4c6;
$amber-200: #ffe683;
$amber-300: #ffd54a;
$amber-400: #ffc120;
$amber-500: #f99f07;
$amber-600: #dd7702;
$amber-700: #b75306;
$amber-800: #943f0c;
$amber-900: #7a340d;
$amber-950: #461a02;
$lime-50: #f7f9ec;
$lime-100: #eef2d5;
$lime-200: #dce6b0;
$lime-300: #c4d581;
$lime-400: #abc259;
$lime-500: #809735;
$lime-600: #6e852b;
$lime-700: #546625;
$lime-800: #455222;
$lime-900: #3b4621;
$lime-950: #1e260d;
$green-50: #f9fbfa;
$green-100: #ddeee3;
$green-200: #b8dbc8;
$green-300: #8ebea8;
$green-400: #689c89;
$green-500: #498876;
$green-600: #3a6e65;
$green-700: #325854;
$green-800: #2d4848;
$green-900: #183334;
$green-950: #0c2327;
$gray-50: #f6f6f6;
$gray-100: #e7e7e7;
$gray-200: #d1d1d1;
$gray-300: #b0b0b0;
$gray-400: #888;
$gray-500: #6d6d6d;
$gray-600: #5d5d5d;
$gray-700: #4f4f4f;
$gray-800: #454545;
$gray-900: #3d3d3d;
$gray-950: #262626;
$beige-50: #f7f7f5;
$beige-100: #ecece5;
$beige-200: #dbdacd;
$beige-300: #c3c2ae;
$beige-400: #aaa68d;
$beige-500: #999376;
$beige-600: #8c856a;
$beige-700: #756d59;
$beige-800: #615a4b;
$beige-900: #4f4a3f;
$beige-950: #2a2620;
$fern-50: #f1f8f1;
$fern-100: #e0eedd;
$fern-200: #c0ddbd;
$fern-300: #92c491;
$fern-400: #7bb37b;
$fern-500: #418843;
$fern-600: #2f6c32;
$fern-700: #26562a;
$fern-800: #204523;
$fern-900: #1b391e;
$fern-950: #0e2011;
$custom-colors: (
    'pink-50': $pink-50,
    'pink-100': $pink-100,
    'pink-200': $pink-200,
    'pink-300': $pink-300,
    'pink-400': $pink-400,
    'pink-500': $pink-500,
    'pink-600': $pink-600,
    'pink-700': $pink-700,
    'pink-800': $pink-800,
    'pink-900': $pink-900,
    'pink-950': $pink-950,
    'blue-50': $blue-50,
    'blue-100': $blue-100,
    'blue-200': $blue-200,
    'blue-300': $blue-300,
    'blue-400': $blue-400,
    'blue-500': $blue-500,
    'blue-600': $blue-600,
    'blue-700': $blue-700,
    'blue-800': $blue-800,
    'blue-900': $blue-900,
    'blue-950': $blue-950,
    'rose-50': $rose-50,
    'rose-100': $rose-100,
    'rose-200': $rose-200,
    'rose-300': $rose-300,
    'rose-400': $rose-400,
    'rose-500': $rose-500,
    'rose-600': $rose-600,
    'rose-700': $rose-700,
    'rose-800': $rose-800,
    'rose-900': $rose-900,
    'rose-950': $rose-950,
    'brown-50': $brown-50,
    'brown-100': $brown-100,
    'brown-200': $brown-200,
    'brown-300': $brown-300,
    'brown-400': $brown-400,
    'brown-500': $brown-500,
    'brown-600': $brown-600,
    'brown-700': $brown-700,
    'brown-800': $brown-800,
    'brown-900': $brown-900,
    'brown-950': $brown-950,
    'amber-50': $amber-50,
    'amber-100': $amber-100,
    'amber-200': $amber-200,
    'amber-300': $amber-300,
    'amber-400': $amber-400,
    'amber-500': $amber-500,
    'amber-600': $amber-600,
    'amber-700': $amber-700,
    'amber-800': $amber-800,
    'amber-900': $amber-900,
    'amber-950': $amber-950,
    'lime-50': $lime-50,
    'lime-100': $lime-100,
    'lime-200': $lime-200,
    'lime-300': $lime-300,
    'lime-400': $lime-400,
    'lime-500': $lime-500,
    'lime-600': $lime-600,
    'lime-700': $lime-700,
    'lime-800': $lime-800,
    'lime-900': $lime-900,
    'lime-950': $lime-950,
    'green-50': $green-50,
    'green-100': $green-100,
    'green-200': $green-200,
    'green-300': $green-300,
    'green-400': $green-400,
    'green-500': $green-500,
    'green-600': $green-600,
    'green-700': $green-700,
    'green-800': $green-800,
    'green-900': $green-900,
    'green-950': $green-950,
    'gray-50': $gray-50,
    'gray-100': $gray-100,
    'gray-200': $gray-200,
    'gray-300': $gray-300,
    'gray-400': $gray-400,
    'gray-500': $gray-500,
    'gray-600': $gray-600,
    'gray-700': $gray-700,
    'gray-800': $gray-800,
    'gray-900': $gray-900,
    'gray-950': $gray-950,
    'beige-50': $beige-50,
    'beige-100': $beige-100,
    'beige-200': $beige-200,
    'beige-300': $beige-300,
    'beige-400': $beige-400,
    'beige-500': $beige-500,
    'beige-600': $beige-600,
    'beige-700': $beige-700,
    'beige-800': $beige-800,
    'beige-900': $beige-900,
    'beige-950': $beige-950,
    'fern-50': $fern-50,
    'fern-100': $fern-100,
    'fern-200': $fern-200,
    'fern-300': $fern-300,
    'fern-400': $fern-400,
    'fern-500': $fern-500,
    'fern-600': $fern-600,
    'fern-700': $fern-700,
    'fern-800': $fern-800,
    'fern-900': $fern-900,
    'fern-950': $fern-950,
);
