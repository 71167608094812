footer {
    .accordion-button {
        &:focus {
            box-shadow: none;
        }

        &:not(.collapsed) {
            box-shadow: none;
        }
    }

    .icon {
        font-size: 20px;
    }

    .legal-links {
        @include media-breakpoint-up(md) {
            a {
                width: auto !important;
            }
        }
    }
}
