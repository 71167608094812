@mixin message($color) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border: 1px solid $color;
    border-radius: 8px;
    gap: 10px;

    .content {
        flex: 1;
    }

    .icon {
        color: $color;
    }

    p {
        margin-bottom: 0;

        &.title {
            font-family: $font-base-bold;
        }

        &.highlight {
            padding: 4px 8px;
            border-radius: 4px;
            background-color: $extra-light-primary;
            color: $extra-dark-primary;
            font-size: $font-small;
        }
    }

    a {
        color: $color;
        text-decoration: underline;

        &:hover {
            color: $color;
        }

        &.tip {
            text-decoration: none;

            .icon {
                color: $gray-99;
            }

            &:hover {
                color: $gray-99;
            }
        }
    }
}

.flash {
    margin-top: 15px;

    &.msn-success {
        background-color: $light-success;
    }
}

.msn-info {
    @include message($green-apple);
}

.msn-warning {
    @include message($warning);
}

.msn-error {
    @include message($error);
}

.msn-success {
    @include message($success);
}

.msn-light-primary {
    @include message($light-primary);
}

.msn-promotion {
    @include message(#FECACA);

    background-color: #FECACA;
    color: #171717;

    .icon {
        padding: 8px;
        border-radius: 50%;
        background-color: #FFF1F2;
        color: #171717;
        font-size: 24px;
    }
}
