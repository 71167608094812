.container {
    @include media-breakpoint-down(sm) {
        padding: 0 20px;
    }
}

.mobile-overflow {
    @include media-breakpoint-down(sm) {
        margin-left: -20px;
        margin-right: -20px;
    }
}
