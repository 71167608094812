.number-counter {
    min-width: 225px;

    .number-counter-title {
        font-size: 80px;
        letter-spacing: -3.2px;
        line-height: 72px;
    }
}

.bordered-spacer {
    width: 240px;
    height: 2px;
    background-color: $green-100;

    @include media-breakpoint-up(lg) {
        width: 2px;
        height: 80px;
    }
}

.fade-image-two-cols {
    .right-col {
        width: 100%;
    }

    .title {
        font-size: 56px;
        letter-spacing: -1.12px;
        line-height: 48px;
    }

    .fade-image-block {
        height: 144px;
    }

    @include media-breakpoint-down(lg) {
        .fade-image-block {
            img {
                width: auto;
                height: 100%;
            }
        }
    }

    @include media-breakpoint-only(lg) {
        .fade-image-block {
            img {
                max-width: 250px;
                height: auto;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .right-col {
            width: 616px;
        }

        .title {
            font-size: 64px;
            letter-spacing: -1.28px;
            line-height: 56px;
        }

        .fade-image-block {
            height: fit-content;
        }
    }

    @include media-breakpoint-up(xxl) {
        .fade-image-block {
            &:nth-child(2),
            &:nth-child(4) {
                top: -32px;
            }
        }
    }
}

.fade-image-grid {
    > div:nth-child(1) {
        img {
            width: 100%;
            height: auto;
        }
    }

    @include media-breakpoint-up(lg) {
        display: grid;
        grid-gap: 24px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);

        > div:nth-child(1) {
            grid-column: 1 / span 2;

            .content {
                min-width: 400px;
                flex: 0 1 0;
                order: -1;
            }

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        > div:nth-child(2) {
            grid-row: 2 / span 2;
        }

        > div:nth-child(3) {
            grid-row: 2;
        }

        > div:nth-child(4) {
            grid-row: 3;
        }
    }
}

.exclusive-conditions {
    .overflow-background {
        position: relative;

        &::before {
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            left: 0;
            width: calc(100% + 16px);
            height: 100%;
            margin-left: -8px;
            background-color: $green-800;
            content: '';
        }
    }

    .title {
        font-size: 40px;
        letter-spacing: -0.4px;
        line-height: 44px;
    }

    .exclusive-condition-content {
        width: 100%;
    }

    @include media-breakpoint-up(lg) {
        .overflow-background {
            &::before {
                left: auto;
                width: 100vh;
                margin-left: 0;
            }
        }

        .title {
            position: relative;
            font-size: 56px;
            letter-spacing: -1.12px;
            line-height: 56px;
        }

        .exclusive-condition-content {
            width: calc(50% - 47px);
        }
    }
}

.fronda-projects {
    .keen-slider__slide {
        min-width: 100%;
        max-width: 100%;
    }

    @include media-breakpoint-up(lg) {
        .keen-slider__slide {
            min-width: calc(50% - 12px);
            max-width: calc(50% - 12px);
        }
    }
}
