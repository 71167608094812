.text-block {
    max-width: 648px;
    margin: 0 auto 24px;

    &.inline {
        max-width: 648px;
    }

    &.overflow {
        max-width: 870px;
    }

    h3 {
        margin-bottom: 12px;
    }
}

.featured-text-block {
    max-width: 870px;
    margin: 40px auto;
    text-align: center;
}

.list-block {
    max-width: 648px;

    &.one {
        li {
            margin-bottom: 16px;
        }
    }

    &.two {
        li {
            margin-bottom: 8px;
        }
    }

    @media #{$big-query} {
        &.two {
            display: flex;
            flex-wrap: wrap;

            li {
                flex-basis: 50%;
            }
        }
    }
}
