.form-input {
    margin-bottom: 30px;

    .input {
        position: relative;
        width: fit-content;

        .icon {
            position: absolute;
            top: 0;
            display: flex;
            width: 50px;
            height: 40px;
            align-items: center;
            justify-content: center;

            & + input {
                padding-left: 50px;
            }

            & + input + .icon {
                right: 0;
            }
        }

        input {
            min-width: 275px;
            height: 40px;
            padding: 8px 16px;
            border: 1px solid $gray-300;
            border-radius: 4px;

            @media (width <= 359px) {
                min-width: 0;
            }

            &:hover {
                border-color: $gray-600;
            }

            &::placeholder {
                color: $gray-300;
            }

            &:not(:placeholder-shown) {
                background-color: $gray-50;
            }

            &:focus,
            &:focus-visible,
            &:active {
                border: 2px solid $gray-950;
                box-shadow: none;
            }

            &.readonly {
                border-color: $gray-200;
                background-color: $gray-50;
                color: $gray-950;
                pointer-events: none;
            }
        }
    }

    .icon {
        font-size: 1.5em;
    }

    .select {
        select {
            width: 100%;
            height: 40px;
            padding: 8px 16px;
            border: 1px solid $gray-bf;
            border-radius: 4px;
        }
    }

    textarea {
        width: 100%;
        padding: 8px 16px;
        border: 1px solid $gray-bf;
        border-radius: 4px;
        resize: none;
    }

    &.country {
        display: none;
        pointer-events: none;

        select {
            appearance: none;
        }
    }

    &.error {
        .input input,
        textarea,
        select {
            border: 2px solid $rose-700;
            background-color: $rose-50;
        }
    }
}

.form-check {
    .form-check-input {
        width: 20px;
        height: 20px;
        border: 1px solid $gray-950;
        cursor: pointer;

        &:checked {
            border-color: $green-700;
            background-color: $green-700;
            background-size: 12px;
        }

        &:focus {
            box-shadow: none;
        }

        &:active {
            filter: none;
        }
    }

    .form-check-label {
        cursor: pointer;
    }

    &.error {
        .form-check-input {
            border-color: $rose-700;
        }
    }
}

.form-toggle-check {
    position: relative;
    margin-top: 10px;

    input {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        opacity: 0;
        outline: none;

        &:checked + label {
            &::before {
                background-color: $primary;
            }

            &::after {
                left: 34px;
            }
        }
    }

    label {
        position: relative;
        padding-left: 76px;
        cursor: pointer;
        font-family: $font-base-bold;

        &::before {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 0;
            display: block;
            width: 68px;
            height: 38px;
            border-radius: 30px;
            background-color: $gray-bf;
            content: '';
            transform: translateY(-50%);
        }

        &::after {
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 4px;
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $white;
            content: '';
            transform: translateY(-50%);
        }
    }
}

.form-radio-background {
    > label {
        font-family: $font-base-bold;
    }

    .inputs {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 24px;
        gap: 16px;

        @media (width <= 414px) {
            gap: 10px;
        }

        &.delivery {
            margin-bottom: 0;

            .input {
                margin-top: 0;

                .icon {
                    font-size: 32px;
                }

                input {
                    + label {
                        font-family: $font-base-semi-bold;
                        font-size: 18px;
                        gap: 8px;
                    }
                }
            }
        }

        .input {
            flex-basis: 100%;
            padding: 16px 24px;
            border-radius: 16px;
            background-color: $gray-f2;
            color: $gray-33;
            cursor: pointer;

            @media (width <= 414px) {
                padding: 10px;
            }

            &:hover {
                background-color: $extra-light-primary;
            }

            &.checked {
                background-color: $extra-light-primary;
            }

            &.new-address {
                order: 2;
                padding: 0;
                margin-top: 25px;
                background: none;
            }

            &.shipment {
                .caption-3 {
                    margin-left: 32px;
                }
            }

            .header {
                display: flex;
                justify-content: space-between;
                font-family: $font-base-bold;

                @media (width <= 359px) {
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 5px;
                }
            }

            .description {
                margin-top: 16px;
            }

            input {
                + label {
                    justify-content: flex-start;
                    text-align: left;
                }
            }
        }
    }

    @media #{$big-query} {
        .inputs {
            &.delivery {
                .input {
                    &:not(.new-address) {
                        flex-basis: 100%;
                    }
                }
            }

            .input {
                &.shipment {
                    flex-basis: 100%;

                    .caption-3 {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}

.form-quantity {
    display: flex;
    height: 32px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $gray-200;
    border-radius: 50px;

    input {
        border: none;
        appearance: textfield;
        text-align: center;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            margin: 0;
            appearance: none;
        }
    }

    .minus,
    .plus {
        display: flex;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        color: $gray-500;
        cursor: pointer;
        font-size: 20px;
        line-height: 15px;

        &:hover {
            border-radius: 50%;
            background-color: $gray-50;
            color: $gray-950;
        }
    }

    &:hover {
        border-color: $gray-950;
    }

    @include media-breakpoint-up(lg) {
        height: 40px;

        .minus,
        .plus {
            width: 38px;
            height: 38px;
            font-size: 20px;
        }
    }
}

.validation-error {
    color: $error;
    font-family: $font-base-semi-bold;
    font-size: $font-small;
}

input[type='radio'] {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    outline: none;

    &:checked {
        + label {
            &::before {
                padding: 3px;
                border-color: $primary;
                background-clip: content-box;
                background-color: $primary;
            }
        }
    }

    + label {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 8px;

        &::before {
            z-index: 1;
            top: 0;
            left: 0;
            display: block;
            width: 24px;
            height: 24px;
            flex: 0 0 auto;
            border: 2px solid $gray-bf;
            border-radius: 100%;
            background-color: $white;
            content: '';
        }
    }
}

.two-inputs {
    @media #{$big-query} {
        display: flex;
        gap: 24px;

        .form-input {
            flex-basis: 50%;

            .input {
                input {
                    min-width: 0;
                }
            }
        }
    }
}

.form-email {
    position: relative;
    max-width: 340px;
    margin: 0 auto;

    input {
        width: 100%;
        height: 58px;
        padding: 0 16px;
        border: none;
        border-radius: 32px;
        box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    }

    .btn-circle {
        position: absolute;
        z-index: 2;
        top: 5px;
        right: 6px;
        display: flex;
        width: 48px;
        height: 48px;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: none;
        border-radius: 50px;
        background-color: $primary;
        color: $white;
    }
}
