@mixin btn($color, $border-color, $background-color, $background-hover) {
    display: inline-block;
    min-width: 285px;
    padding: 10px 15px;
    border: 2px solid $border-color;
    border-radius: 32px;
    background-color: $background-color;
    color: $color;
    cursor: pointer;
    font-family: $font-base-semi-bold;
    text-align: center;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        border-color: $border-color;
        background-color: $background-hover;
        box-shadow: none;
        color: $color;
        text-decoration: none;
    }

    @media (width <= 359px) {
        min-width: 0;
    }
}

@mixin btn-icon($color, $border-color, $background-color, $background-hover) {
    @include btn($color, $border-color, $background-color, $background-hover);

    display: flex;
    width: fit-content;
    min-width: 0;
    align-items: center;
    padding: 10px 20px;

    .icon {
        padding-right: 10px;
    }
}

@mixin btn-right-icon($color, $border-color, $background-color, $background-hover) {
    @include btn($color, $border-color, $background-color, $background-hover);

    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.btn-primary-old {
    @include btn($white, $pink-600, $pink-600, $pink-700);
}

.btn-primary-inverse {
    @include btn($gray-4f, $secondary, $white, $white);
}

.btn-secondary-old {
    @include btn($white, $primary, $primary, $extra-dark-primary);
}

.btn-secondary-icon {
    @include btn-icon($white, $primary, $primary, $extra-dark-primary);
}

.btn-secondary-right-icon {
    @include btn-right-icon($white, $primary, $primary, $extra-dark-primary);
}

.btn-tertiary {
    @include btn($primary, $primary, $white, $extra-light-primary);
}

.btn-tertiary-icon {
    @include btn-icon($primary, $primary, $white, $extra-light-primary);
}

.btn-tertiary-right-icon {
    @include btn-right-icon($primary, $primary, $white, $extra-light-primary);
}

.btn-club {
    @include btn($white, $club, $club, $light-club);
}

.btn-club-secondary {
    @include btn($white, $club-secondary, $club-secondary, $light-club-secondary);
}

.btn-contact {
    @include btn-icon($white, $extra-dark-primary, $extra-dark-primary, $extra-dark-primary);

    padding: 10px;
}

.btn-spinner {
    &.loading {
        opacity: 0.75;
        pointer-events: none;

        > *:not(.loader) {
            display: none;
        }

        .loader {
            display: flex;
            flex-basis: 100%;
            align-items: center;
            justify-content: space-between;

            .spinner-border {
                margin-left: 8px;
            }
        }
    }

    .loader {
        display: none;
    }
}
