.block-image-text {
    max-width: 870px;
    margin: 0 auto 64px;

    .content {
        h2,
        h3,
        h4 {
            margin-bottom: 16px;
            color: $primary;
        }
    }

    .image {
        .image-caption {
            color: $gray-99;
            font-size: $font-small;
        }
    }

    @media #{$big-query} {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 32px;

        .content {
            width: 66%;
        }

        .image {
            width: 30%;

            img {
                max-width: 100%;
            }

            .image-caption {
                text-align: right;
            }
        }

        &.inverted {
            .content {
                order: 2;
            }

            .image-caption {
                text-align: left;
            }
        }
    }
}
