.blog {
    .main-content {
        a {
            text-decoration: underline;

            &[class^='btn-'],
            &.item__link {
                text-decoration: none;
            }
        }
    }

    .product__item {
        a {
            text-decoration: none;
        }
    }
}
