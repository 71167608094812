/* ------ Variables ------ */

// Color system
$white: #fff;
$gray-f2: #f2f2f2;
$gray-bf: #bfbfbf;
$gray-99: #999;
$gray-4f: #4f4f4f;
$gray-33: #333;

// Backgrounds
$sand: #f0ecd9;
$cappuccino: #cdc4a4;
$mole: #cacdbf;

// Primary
$extra-light-primary: #e8f2ec;
$light-primary: #7d9889;
$primary: #527561;
$dark-primary: #3e5849;
$extra-dark-primary: #293b31;

// Secondary
$light-secondary: #c76b87;
$secondary: #b43a5f;
$dark-secondary: #872c47;

// Tertiary
$green-apple: #84a02b;
$yellow-stars: #edd881;
$candy-pink: #e57171;

// Semantic
$light-error: #fbdddd;
$error: #eb5757;
$light-warning: #fae4c5;
$warning: #eda63c;
$light-success: #edf5f9;
$success: #458ec9;
$club: #e56a8f;
$light-club: #f2a8b6;
$club-secondary: #5a6244;
$light-club-secondary: #b6baa7;
$opacity-25-green-apple: rgb(132 160 43 / 25%) !default;
$opacity-55-green-apple: rgb(132 160 43 / 55%) !default;
$opacity-25-green-dark-eucalyptus: rgb(82 117 97 / 25%) !default;
$font-small: 14px;
$font-normal: 16px;
$font-medium: 20px;
$font-heading-1: 40px;
$font-heading-2: 33px;
$font-heading-3: 28px;
$font-heading-4: 23px;
$font-alter-heading-2: 48px;
$font-alter-heading-3: 40px;
$font-weight-semi-bold: 500;

// Media queries (Tamaño por defecto mobile first, query para pc $big-query < 992px)
$small-query: '(min-width: 20.625em) and (max-width: 25.875em)'; // Mobile portrait "(min-width : 330px) and (max-width: 414px)"
$mobile-query: '(max-width: 36em)'; // Small devices (Mobile portrait and Mobile landscape) "(max-width : 576px)"
$tablet-query: '(min-width: 36em) and (max-width: 62em)'; // Mobile landscape and Tablets portrait "(min-width : 576px) and (max-width : 992px)"
$tablet-laptop-query: '(min-width: 36em) and (max-width: 87.5em)'; // Mobile landscape, Tablets portrait and laptop "(min-width : 576px) and (max-width : 1400px)"
$responsive-query: '(max-width: 62em)'; // Mobile portrait, Mobile landscape and Tablets portrait "(max-width : 992px)"
$medium-query: '(min-width: 36em) and (max-width: 90em)'; // Tablets, Laptops and desktops "(max-width : 992px) and (max-width: 1440px)"
$big-query: '(min-width: 62em)'; // All Laptops and desktops "(min-width : 992px)"
