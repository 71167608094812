.centered-subtitle {
    max-width: 650px;
    margin: 0 auto;
    font-family: $font-base-semi-bold;
    font-size: $font-medium;
    line-height: 28px;
    text-align: center;
}

.number-list {
    list-style-type: decimal;

    .title {
        @extend h3;

        margin-bottom: 8px;
        color: $primary;
    }

    > li {
        margin-top: 48px;

        &::marker {
            @extend h1;

            color: $primary;
        }

        ul {
            list-style: disc;
        }
    }

    @media #{$big-query} {
        padding-left: 128px;

        > li {
            padding-left: 20px;
        }
    }
}

.dot-list {
    .title {
        @extend h3;

        margin-bottom: 8px;
        color: $primary;
    }

    li {
        margin-top: 48px;

        &::marker {
            color: $primary;
        }
    }
}

.shipping-zones {
    .zone {
        display: flex;
        align-items: center;
        margin-top: 32px;
    }

    @media #{$big-query} {
        display: flex;
        gap: 48px;

        .zone {
            flex-basis: 48%;
            gap: 16px;
        }
    }
}

.inline-link {
    a {
        display: inline-flex;
        align-items: center;
        font-family: $font-base-bold;
        gap: 8px;
    }
}

.inline-desktop {
    text-align: center;

    > * {
        margin: 0 auto 16px;
    }

    @media #{$big-query} {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        > * {
            margin: 0;
        }

        p {
            margin-bottom: 0;
        }
    }
}
