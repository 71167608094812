.bg-opacity-modal {
    @media #{$big-query} {
        background-color: rgb(0 0 0 / 25%);
    }

    position: fixed;
    z-index: 3;
    display: none;
    width: 100%;
    height: 100%;
    inset: 180px 0 0;

    &.bg-white-active {
        top: 0;
        display: block;
        background-color: rgb(0 0 0 / 25%);
    }
}

.block-scroll {
    overflow: hidden;
    height: 100%;

    /* Safari hack */
    @supports (-webkit-touch-callout: none) {
        height: fill-available;
    }

    @media #{$big-query} {
        overflow: auto;
        height: auto;
    }
}

.header-top {
    position: relative;
    z-index: 6;
    width: 100%;
    background-color: $white;

    &.sticky {
        position: sticky;
        top: 0;
        animation: slidedown 0.4s;
    }

    &.fixed {
        position: fixed;
        top: auto;
    }

    &.hide {
        top: -212px;
        animation: slideup 0.4s;
    }

    .header-logo {
        height: 32px;
    }

    .flex-container {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0 8px;

        .flex-items {
            &.navbar-toggler {
                order: 3;
                padding: 5px;
                margin-right: 13px;
            }

            &.search {
                flex: 1;
                order: 4;

                .search-item {
                    position: relative;
                    display: grid;
                    width: 100%;
                    height: 40px;
                    cursor: pointer;

                    &:hover {
                        .search__input {
                            border-color: $green-700;

                            &::placeholder {
                                color: $gray-950;
                            }
                        }
                    }

                    .search__input {
                        padding: 0 16px 0 48px;
                        border: 1px solid $gray-200;
                        border-radius: 24px;
                        background-color: $gray-50;
                        cursor: pointer;
                        font-size: $font-small;

                        &::placeholder {
                            color: $gray-700;
                        }

                        &:active,
                        &:focus {
                            background-color: $gray-100;

                            &::placeholder {
                                color: $gray-950;
                            }
                        }
                    }
                }
            }

            &.links-item {
                width: 50%;
                order: 2;
                margin-bottom: 16px;
                font-family: $font-base-bold;

                ul {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    margin-bottom: 0;
                    gap: 8px;
                    list-style: none;

                    li {
                        &:hover {
                            background-color: $gray-50;

                            a {
                                color: $gray-950;
                            }
                        }

                        &:active,
                        &:focus {
                            background-color: $gray-100;
                            color: $gray-950;
                        }

                        a {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                            color: $gray-800;
                            font-family: $font-base-medium;
                            font-size: 12px;
                            line-height: 16px;
                            text-decoration-line: none;

                            p {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    button.navbar-toggler {
        padding: 0;
        color: $light-primary;

        &:focus {
            box-shadow: none;
        }
    }

    .navbar {
        position: absolute;
        z-index: 10;
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: 1px solid $gray-100;
        background-color: $white;

        @media #{$responsive-query} {
            &.active {
                top: 0;
                height: 100vh;
                align-content: flex-start;
                padding-bottom: 30vh;
                overflow-y: auto;

                .container {
                    padding: 0;
                }
            }
        }

        &.fixed-top .navbar-collapse,
        .navbar.sticky-top .navbar-collapse {
            max-height: 90vh;
            margin-top: 10px;
            overflow-y: auto;
        }

        .nav-link {
            display: flex;
            align-items: center;
            padding: 20px 24px;
            border-top: 1px solid $gray-f2;
            color: $gray-800;
            cursor: pointer;
            font-family: $font-base-semi-bold;
            text-decoration: none;

            p {
                flex-grow: 1;
                margin: 0;
            }

            &.dropdown-toggle::after {
                display: none;
            }

            &.featured-nav-link {
                color: $pink-600;
            }
        }

        .dropdown-menu {
            padding: 0;
            border: none;
            border-radius: 0;
            color: $gray-33;
            font-size: inherit;
            list-style: none;
            text-align: left;

            @media #{$responsive-query} {
                &[data-bs-popper] {
                    margin-top: 0;
                }
            }

            .nav__category-second-level {
                .second-level__title,
                .third-level__title,
                .fourth-level__title {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: 20px 24px;
                    border-top: 1px solid $gray-f2;
                    color: $gray-4f;
                    font-family: $font-base-semi-bold;

                    .img_nav-circle {
                        border-radius: 50px;
                        margin-right: 10px;
                    }

                    p {
                        flex-grow: 1;
                        margin: 0;
                    }
                }

                .second-level__title {
                    padding: 17px 25px;
                }

                .nav__category-third-level,
                .nav__category-fourth-level {
                    padding-left: 0;
                    list-style: none;

                    a {
                        color: $gray-4f;
                    }
                }
            }

            .nav__mobile-title {
                display: flex;
                align-items: center;
                color: $gray-4f;
                font-family: $font-base-semi-bold;

                a {
                    padding: 20px 24px;
                    border-top: none;
                    color: $gray-4f;
                    font-family: $font-base-semi-bold;
                }
            }

            .featured-item {
                a {
                    &:hover {
                        .image::after {
                            position: absolute;
                            border-radius: 4px;
                            background: linear-gradient(0deg, rgb(255 255 255 / 10%), rgb(255 255 255 / 10%));
                            content: '';
                            inset: 0;
                        }
                    }
                }
            }
        }
    }

    @media #{$big-query} {
        .header-logo {
            height: 27px;
        }

        .flex-container {
            flex-wrap: nowrap;

            .flex-items {
                &.search {
                    min-width: 450px;
                    max-width: 520px;
                    order: 2;
                }

                &.links-item {
                    width: auto;
                    flex: 1;
                    order: 3;
                    margin-bottom: 0;

                    ul li {
                        a {
                            padding: 2px 8px 2px 2px;

                            p {
                                display: block;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        .navbar-toggler {
            display: none;
        }

        .navbar {
            position: relative;

            .nav-link {
                height: 100%;
                padding: 12px 0;
                border-top: none;
                border-bottom: 2px solid transparent;
                margin-right: 24px;
                color: $gray-950;
                font-family: $font-base-medium;
                font-size: 14px;
                line-height: 20px;

                &.featured-nav-link {
                    &:hover {
                        border-color: $pink-700;
                        color: $pink-700;
                    }

                    &:active,
                    &:focus {
                        border-color: $pink-600;
                        color: $pink-600;
                    }
                }
            }

            .has-megamenu {
                position: static !important;
            }

            .category-first-level {
                transition: none;

                &:hover {
                    border-bottom: 2px solid $gray-950;
                    color: $gray-950;
                }
            }

            .dropdown-menu {
                &.megamenu {
                    right: 0;
                    left: 0;
                    width: 100%;
                    border-radius: 0;
                    border-top: 1px solid $gray-200;

                    .megamenu-container {
                        position: relative;
                        display: flex;
                        height: 470px;
                        flex-flow: column wrap;
                        align-content: flex-start;
                        padding-bottom: 40px;
                    }
                }

                .img_nav-circle {
                    display: none;
                }

                .nav__category-second-level {
                    width: 25%;
                    padding-right: 32px;
                    margin-top: 24px;

                    .second-level__title {
                        padding: 0;
                        border: none;
                        color: $gray-950;
                        font-family: $font-base-extra-bold;
                        font-size: 12px;
                        letter-spacing: 0.48px;
                        line-height: 16px;
                        text-transform: uppercase;
                    }

                    .nav__category-third-level {
                        margin-top: 12px;

                        .third-level__title {
                            padding: 0;
                            border: none;
                            margin-top: 12px;
                            color: $gray-800;
                            font-family: $font-base-medium;
                            font-size: 14px;
                            line-height: 20px;

                            &:hover {
                                color: $gray-950;
                            }
                        }
                    }

                    .nav__category-fourth-level {
                        display: none;
                    }
                }

                .nav__mobile-title {
                    display: none;
                }
            }

            .nav-info {
                .nav-link.show {
                    color: $green-apple;

                    &:hover {
                        color: $light-primary;
                    }

                    &:active,
                    &.focus-within {
                        color: $green-apple;
                    }
                }

                .dropdown-menu.show {
                    display: inline-block;
                    height: auto;
                    border-radius: 0 0 4px 4px;
                    border-top: 3px solid $green-apple;
                    border-left: none;
                    margin-bottom: 0;
                    margin-left: 0;
                    overflow-y: initial;

                    .dropdown-item {
                        color: $light-primary;
                        font-family: $font-base-bold;

                        &:hover,
                        &:focus {
                            background-color: $gray-f2;
                            color: $primary;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .navbar {
            .dropdown-menu.megamenu .megamenu-container {
                height: 485px;
            }

            .dropdown-menu .nav__category-second-level {
                width: 16.66%;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .logo {
            img {
                height: 26px;
            }
        }
    }
}

@media #{$responsive-query} {
    .rm-mobile .rm-view {
        display: block !important;
    }

    .rm-mobile .rm-hidden {
        display: none !important;
    }

    .rm-mobile .rm-visible {
        display: block;
    }

    .rm-mobile .rm-hidden-header {
        display: none !important;
    }

    .rm-submenu {
        display: none !important;
    }

    .navbar .nav-info {
        padding: 10px 0;
        margin-top: 25px;

        .nav-link {
            min-height: auto;
            border-bottom: none;
            color: $light-primary;

            &.active {
                color: $green-apple;
            }
        }

        .dropdown-menu {
            padding: 0;
            border-left: 2px solid $green-apple;
            margin-bottom: 20px;
            margin-left: 15px;

            li a {
                height: 48px;
                padding: 13px 15px;
            }
        }
    }
}

@keyframes slideup {
    0% {
        top: 0;
    }

    75% {
        top: 0;
    }

    100% {
        top: -156px;
    }
}

@keyframes slidedown {
    0% {
        top: -156px;
    }

    75% {
        top: 0;
    }

    100% {
        top: 0;
    }
}

@media (width <= 359px) {
    .header-top {
        .flex-container {
            .flex-items.logo {
                max-width: 85px;
                padding-right: 0;
            }
        }
    }
}

// fix temporal para ajustar altura de submenús por categoría
.header-top {
    .navbar {
        @include media-breakpoint-up(lg) {
            .macetas_jardineras {
                .dropdown-menu {
                    .nav__category-second-level {
                        &:nth-child(5) {
                            height: 90%;
                        }
                    }
                }
            }

            .complementos_plantas_flores {
                .dropdown-menu {
                    .nav__category-second-level {
                        &:nth-child(5) {
                            height: 90%;
                        }
                    }
                }
            }

            .jardin {
                .dropdown-menu {
                    .nav__category-second-level {
                        &:nth-child(7) {
                            height: 50%;
                        }
                    }
                }
            }

            .hogar_decoracion {
                .dropdown-menu {
                    .nav__category-second-level {
                        &:nth-child(6) {
                            height: 90%;
                        }
                    }
                }
            }

            .navidad {
                .dropdown-menu {
                    .nav__category-second-level {
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5) {
                            height: 90%;
                        }
                    }
                }
            }
        }
    }
}
