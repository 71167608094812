.two-cols-list {
    margin-bottom: 64px;

    .item {
        position: relative;
        padding-left: 40px;
        margin-top: 24px;

        &::before {
            position: absolute;
            top: 5px;
            left: 0;
            display: inline-block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: $primary;
            content: '';
        }

        .title {
            @extend h4;

            color: $primary;
        }
    }

    @media #{$big-query} {
        display: flex;
        flex-wrap: wrap;
        gap: 22px;

        .item {
            flex-basis: 49%;
            margin-top: 32px;

            .title {
                font-size: $font-heading-3;
                line-height: 32px;
            }
        }
    }
}
