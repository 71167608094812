.special-promotion-banner {
    img {
        width: 100%;
        height: auto;
        min-height: 128px;
        border-radius: 4px;
        object-fit: cover;

        @media #{$mobile-query} {
            width: calc(100% + 24px);
            border-radius: 0;
            margin-left: -12px;
        }
    }

    .content {
        margin-top: 16px;
        color: $gray-33;
        font-family: $font-base-semi-bold;
        font-size: 14px;
        line-height: 20px;

        .title {
            font-size: 28px;
            line-height: 32px;
        }

        .msn-special-promotion {
            padding: 4px 12px;
            border-radius: 0 0 8px;
            margin-right: 8px;
            background: #91c98f;
            color: $gray-33;
            font-family: $font-base-semi-bold;
        }
    }

    @media #{$big-query} {
        position: relative;

        .content {
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            max-width: 655px;
            flex-wrap: wrap;
            align-content: center;
            align-items: center;
            padding: 0 24px;
            margin: 0;
            font-size: 24px;
            line-height: 32px;

            .title {
                margin-bottom: 0;
                color: $white;
                font-size: 40px;
                line-height: 44px;
            }

            .msn-special-promotion-caption {
                color: $white;
            }
        }
    }
}

.landing-hero {
    position: relative;
    max-width: 1512px;
    margin: 0 auto;

    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: right;
    }

    @include media-breakpoint-up(md) {
        .breadcrumb {
            left: 72px;
            padding: 0;
        }

        img {
            height: 450px;
        }

        .content-wrapper {
            position: absolute;
            max-width: 720px;
            margin: 0 auto;
            inset: 0;

            .content {
                max-width: 420px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .content-wrapper {
            max-width: 960px;
        }
    }

    @include media-breakpoint-up(xl) {
        .content-wrapper {
            max-width: 1140px;
        }
    }

    @include media-breakpoint-up(xxl) {
        .content-wrapper {
            max-width: 1320px;
        }
    }
}
