.mobile-gradient {
    @include media-breakpoint-down(lg) {
        &::after {
            position: absolute;
            background: linear-gradient(to bottom, rgb(0 0 0 / 30%), rgb(0 0 0 / 0%) 33%);
            content: '';
            inset: 0;
        }
    }
}
