.cart-summary {
    position: relative;

    .right-column {
        position: relative;

        &::before {
            position: absolute;
            z-index: -1;
            right: 50%;
            left: 50%;
            display: block;
            width: 100vw;
            height: 100%;
            margin-right: -50vw;
            margin-left: -50vw;
            background-color: $beige-50;
            content: '';
        }
    }

    .cart-item-notifications {
        &:empty {
            display: none !important;
        }
    }

    @include media-breakpoint-down(lg) {
        .cart-item-image {
            width: 80px;
            height: 80px;
        }
    }

    @include media-breakpoint-up(lg) {
        &::before {
            position: absolute;
            z-index: -1;
            right: 0;
            display: block;
            width: 50vw;
            height: 100%;
            background-color: $beige-50;
            content: '';
        }

        .right-column {
            &::before {
                content: none;
            }
        }
    }
}
