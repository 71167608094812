.department-list {
    .department-card {
        width: 320px;
        height: 320px;
        margin: 0 auto 24px;

        .image {
            position: relative;
            height: 100%;

            img {
                width: 100%;
                max-width: 100%;
                height: 100%;
                border-radius: 8px;
                object-fit: cover;
            }

            .content {
                position: absolute;
                top: 0;
                display: flex;
                width: 100%;
                height: 100%;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                padding: 24px;
                border-radius: 8px;
                background: linear-gradient(0deg, rgb(0 0 0 / 40%), rgb(0 0 0 / 40%));
                gap: 24px;
                text-align: center;

                h2 {
                    flex-basis: 100%;
                    color: #fff;
                }
            }
        }
    }

    @media #{$big-query} {
        display: flex;
        max-width: 1050px;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        gap: 40px;

        .department-card {
            margin: 0;
        }
    }
}

.job-list {
    .job-card {
        padding: 16px;
        border: 1px solid #bfbfbf;
        border-radius: 8px;
        margin-bottom: 16px;

        p:last-child {
            margin-bottom: 0;
        }
    }

    @media #{$big-query} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 16px;

        .job-card {
            flex-basis: 32%;
            margin-bottom: 0;
        }
    }
}

.centered-title {
    max-width: 650px;
    margin: 0 auto;
    text-align: center;
}
