.error-page-container {
    padding: 0;
    margin: 120px auto;

    .content-wrapper {
        padding: 16px;
        text-align: center;
    }

    .background {
        display: none;
    }

    h1 {
        margin-bottom: 16px;
        color: $primary;
    }

    @media #{$big-query} {
        display: flex;
        align-items: center;
        margin: 0;

        .content-wrapper {
            width: 41.66666667%;
            padding: 0;
        }

        .content {
            max-width: 316px;
            margin: 0 auto;
            text-align: left;
        }

        .background {
            display: block;
            width: 58.33333333%;
            max-height: 800px;

            img {
                width: 100%;
                max-height: 800px;
                object-fit: cover;
            }
        }
    }
}
