.category-landing {
    .landing-top-banner {
        img {
            display: none;
            width: 100%;
            height: auto;
            min-height: 128px;
            border-radius: 4px;
            object-fit: cover;

            @media #{$mobile-query} {
                width: calc(100% + 24px);
                border-radius: 0;
                margin-left: -12px;
            }
        }

        .content {
            margin: 0 auto 8px;

            h1 {
                font-size: 32px;
                line-height: 36px;
            }
        }
    }

    .product-list {
        margin-top: 48px;
        margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        .landing-top-banner {
            position: relative;

            img {
                display: inline;
            }

            .content {
                position: absolute;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                padding: 0 72px;
                margin: 0;

                h1 {
                    color: $white;
                    font-size: 40px;
                    line-height: 44px;
                }
            }
        }
    }
}
