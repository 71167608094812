.generated-content {
    a {
        text-decoration: underline;
    }
}

.product-generated-content {
    ul {
        padding-left: 1.5rem;
        margin-bottom: 0;
    }

    li {
        margin-bottom: 12px;
    }

    a {
        @extend .text-blue-700;
        @extend .text-decoration-underline;
        @extend .link-offset-1;
        @extend .link-underline;
        @extend .link-underline-opacity-0;
        @extend .link-underline-opacity-100-hover;
    }
}
