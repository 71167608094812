$font-base: 'Figtree-Regular', sans-serif;
$font-base-medium: 'Figtree-Medium', sans-serif;
$font-base-semi-bold: 'Figtree-SemiBold', sans-serif;
$font-base-bold: 'Figtree-Bold', sans-serif;
$font-base-extra-bold: 'Figtree-ExtraBold', sans-serif;

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .title#{$infix}-1 {
            font-family: $font-base-medium;
            font-size: 44px;
            letter-spacing: -0.02em;
            line-height: 1em;
        }

        .title#{$infix}-2 {
            font-family: $font-base-medium;
            font-size: 40px;
            letter-spacing: -0.01em;
            line-height: 1em;
        }

        .title#{$infix}-3 {
            font-family: $font-base-medium;
            font-size: 32px;
            letter-spacing: -0.01em;
            line-height: 1em;
        }

        .title#{$infix}-4 {
            font-family: $font-base-medium;
            font-size: 28px;
            letter-spacing: -0.01em;
            line-height: 1.125em;
        }

        .title#{$infix}-5 {
            font-family: $font-base-medium;
            font-size: 24px;
            letter-spacing: -0.01em;
            line-height: 1.125em;
        }

        .title#{$infix}-6 {
            font-family: $font-base-medium;
            font-size: 20px;
            line-height: 1.125em;
        }

        .title#{$infix}-7 {
            font-size: 18px;
            line-height: 24px;
        }

        .text#{$infix}-1 {
            font-size: 16px;
            line-height: 26px;
        }

        .text#{$infix}-2 {
            font-size: 14px;
            letter-spacing: 0.01em;
            line-height: 22px;
        }

        .text#{$infix}-3 {
            font-size: 12px;
            line-height: 18px;
        }

        .caption#{$infix}-1 {
            font-size: 16px;
            line-height: 20px;
        }

        .caption#{$infix}-2 {
            font-size: 14px;
            line-height: 20px;
        }

        .caption#{$infix}-3 {
            font-size: 12px;
            line-height: 16px;
        }

        .caption#{$infix}-4 {
            font-size: 10px;
            line-height: 12px;
        }

        .label#{$infix}-1 {
            font-size: 14px;
            letter-spacing: 0.04em;
            line-height: 16px;
            text-transform: uppercase;
        }

        .label#{$infix}-2 {
            font-size: 12px;
            letter-spacing: 0.04em;
            line-height: 16px;
            text-transform: uppercase;
        }

        @each $display, $font-size in $display-font-sizes {
            .display#{$infix}-#{$display} {
                font-size: $font-size;
                font-weight: 400;
                letter-spacing: -0.03em;
                line-height: $display-line-height;
            }
        }
    }
}

.font-regular {
    font-family: $font-base;
}

.font-medium {
    font-family: $font-base-medium;
}

.font-semi-bold {
    font-family: $font-base-semi-bold;
}

.font-bold {
    font-family: $font-base-bold;
}

.link {
    &:hover {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
    }
}

.underline {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
}

@for $i from 1 through 10 {
    .text-truncate-#{$i} {
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $i;
    }
}

.first-letter-uppercase {
    &::first-letter {
        text-transform: uppercase;
    }
}
