.customer-page {
    .main-content {
        overflow-x: hidden;
    }
}

.customer-form {
    margin-top: 40px;

    h1 {
        color: $primary;
        text-align: center;
    }

    .form-wrapper {
        position: relative;
        margin: 40px auto 100px;

        &.register {
            .subtitle {
                margin-bottom: 5px;
            }

            .register-inputs {
                margin: 30px auto 40px;
            }
        }

        .background-image-top {
            position: absolute;
            z-index: -1;
            top: -60px;
            right: -50px;
            transform: rotate(160deg);
        }

        .background-image-bottom {
            position: absolute;
            z-index: -1;
            bottom: -60px;
            left: -25px;
        }

        .form-input {
            .input,
            input {
                width: 100%;
            }
        }

        .form-check {
            margin-bottom: 16px;

            .form-check-label {
                font-size: $font-small;
            }
        }

        .subtitle,
        .links,
        .cta {
            text-align: center;
        }

        .subtitle {
            margin-bottom: 30px;
        }

        .links {
            p {
                margin-bottom: 0;
            }

            a {
                flex-basis: 100%;
                color: $primary;
                font-family: $font-base-bold;
                text-decoration: underline;
            }
        }

        .forgot-password {
            p {
                margin-bottom: 0;
            }
        }

        .cta {
            margin: 32px 0;
        }
    }

    @media #{$responsive-query} {
        .form-wrapper {
            .background-image-top {
                img {
                    max-width: 76px;
                }
            }

            .background-image-bottom {
                img {
                    max-width: 131px;
                }
            }
        }
    }

    @media #{$big-query} {
        .form-wrapper {
            max-width: 400px;
            padding: 24px 38px;
            border: 1px solid $gray-bf;
            border-radius: 8px;
            margin-bottom: 360px;
            background-color: $white;

            &.reset-password {
                .background-image-top {
                    top: -30px;
                }
            }

            &.register {
                max-width: 550px;
                margin-bottom: 280px;

                .register-inputs {
                    max-width: 330px;
                }
            }

            .background-image-top {
                top: -80px;
                right: -200px;
                transform: rotate(225deg);
            }

            .background-image-bottom {
                bottom: -230px;
                left: -250px;
            }
        }
    }
}
