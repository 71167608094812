$icomoon-font-family: 'Icons-Fronda' !default;
$icomoon-font-path: '../../fonts/icomoon' !default;
$icon-arrow-down: '\e91c';
$icon-arrow-right: '\e903';
$icon-arrow-up: '\e92d';
$icon-badge-check: '\e900';
$icon-badge-percent-16: '\e952';
$icon-badge-percent-20: '\e953';
$icon-badge-percent-32: '\e934';
$icon-badge-percent: '\e907';
$icon-bell: '\e929';
$icon-bolt-32: '\e933';
$icon-bolt: '\e904';
$icon-calendar-clock-32: '\e932';
$icon-calendar-clock: '\e931';
$icon-calendar-days: '\e948';
$icon-calendar-heart-32: '\e93c';
$icon-calendar-heart-48: '\e939';
$icon-calendar-heart: '\e938';
$icon-calendar: '\e908';
$icon-check-20: '\e977';
$icon-check-check: '\e91f';
$icon-check: '\e909';
$icon-chevron-down: '\e906';
$icon-chevron-left: '\e905';
$icon-chevron-right-20: '\e950';
$icon-chevron-right: '\e90b';
$icon-chevron-up: '\e92b';
$icon-circle-alert: '\e90a';
$icon-circle-check: '\e936';
$icon-circle-help: '\e90c';
$icon-circle-plus: '\e918';
$icon-circle-x: '\e90d';
$icon-credit-card: '\e919';
$icon-eye-off: '\e90e';
$icon-eye: '\e94a';
$icon-facebook: '\e96a';
$icon-gift-16: '\e93d';
$icon-gift-32: '\e93f';
$icon-gift-40: '\e940';
$icon-gift-48: '\e93a';
$icon-gift: '\e958';
$icon-headset: '\e91a';
$icon-heart: '\e91b';
$icon-info: '\e90f';
$icon-instagram: '\e96c';
$icon-leaf-32: '\e941';
$icon-leaf-40: '\e942';
$icon-leaf: '\e94e';
$icon-lock: '\e94b';
$icon-mail-check: '\e928';
$icon-mail: '\e94f';
$icon-map-pin: '\e910';
$icon-megaphone: '\e920';
$icon-menu: '\e911';
$icon-message-circle-more: '\e912';
$icon-minus: '\e921';
$icon-move-down: '\e91d';
$icon-move-left: '\e92e';
$icon-move-right: '\e913';
$icon-package: '\e91e';
$icon-pencil: '\e922';
$icon-phone-call: '\e949';
$icon-phone: '\e902';
$icon-plus: '\e923';
$icon-search: '\e946';
$icon-share: '\e92f';
$icon-shield-check-32: '\e943';
$icon-shield-check-40: '\e944';
$icon-shield-check: '\e914';
$icon-shopping-bag: '\e901';
$icon-shopping-cart-20: '\e94d';
$icon-shopping-cart: '\e915';
$icon-shovel: '\e924';
$icon-sprout: '\e92a';
$icon-store-32: '\e935';
$icon-store: '\e925';
$icon-ticket: '\e94c';
$icon-trash-2-20: '\e951';
$icon-trash-2: '\e926';
$icon-tree-pine-16: '\e93e';
$icon-tree-pine: '\e93b';
$icon-triangle-alert: '\e930';
$icon-truck-32: '\e937';
$icon-truck-40: '\e945';
$icon-truck: '\e947';
$icon-user-round: '\e916';
$icon-wallet: '\e927';
$icon-x: '\e917';
$icon-youtube: '\e96b';
$icon-zoom-in: '\e92c';
