.top-banner {
    position: relative;

    img {
        width: 100%;
        height: auto;
        min-height: 600px;
        object-fit: cover;
    }

    .content {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        display: flex;
        width: 100%;
        max-width: 400px;
        height: 100%;
        flex-wrap: wrap;
        align-content: center;
        margin: 0 auto;

        > * {
            flex-basis: 100%;
            color: $white;
        }

        .club-color {
            color: $club;
        }
    }

    @media #{$big-query} {
        .content {
            right: auto;
            left: 50%;
        }
    }
}
